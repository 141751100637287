<template>

  <v-sheet
      :class="[{'fill-height' : !SM}]"
      class="d-flex justify-center align-center"
      style="position: relative"
      color="white"
  >
    <v-row
        class="no-gutters fill-height" >
      <v-col v-if="!SM" cols="12" md="6">
        <v-sheet :color="wsLIGHTCARD" class="fill-height d-flex align-center justify-center">
          <v-img
              src="/library/img/home/certificate_check.png"
              alt="WeStudy Check Certificate"
              max-height="500"
              max-width="500"
          />
        </v-sheet>
      </v-col>
      <v-col cols="12" md="6" class="px-3">
        <v-sheet class="fill-height d-flex align-center justify-center">

          <div>
            <v-sheet >
              <h1 :style="`color : ${wsDARKER}`">{{ $t('SearchCertificate') }}</h1>
              <h3 :style="`color : ${wsDARKER}`"  class="font-weight-regular">{{ $t('SearchCertificateDescription') }}</h3>
              <div :class="!SM ? 'd-flex' : null" class=" justify-center mt-5" >
                <ws-text-field
                    v-model="certificateNumber"
                    icon="mdi-magnify"
                    :width="!SM ? '100%' : null"
                    :placeholder="$t('CertificateNumber')"
                    :error-messages="certificateError"
                    :error="!!certificateError"
                    :hide-details="!certificateError"
                    @input="certificateError = null"
                />
                <v-btn @click="findCertificate"
                       :class="!SM ? 'ml-n2' : 'mt-3'"
                       style="border-top-left-radius: 0; border-bottom-left-radius: 0;"
                       :block="SM"
                       :color="wsATTENTION"
                       :loading="LOADING" dark
                       elevation="0" height="40" class="noCaps" >{{$t('SearchCertificate')}}</v-btn>
              </div>

            </v-sheet>

            <v-simple-table
                  key="table"
                  v-if="certificate.uuid" class="wsHoverLight mt-10">
                <tbody
                >
                <tr>
                  <td width="200px"
                      style="border-top-left-radius: 8px"
                      :style="`border : 1px solid ${wsDARKLIGHT} ; background : ${wsLIGHTCARD}`"
                  >
                    <h5 :style="`color : ${wsACCENT}`">{{ $t('StudentName') }}</h5>
                  </td>
                  <td :style="`border : 1px solid ${wsDARKLIGHT}; border-left : none !important`"
                      style="border-top-right-radius: 8px"
                  >
                    <h5>{{ certificate.user_name }}</h5>
                  </td>
                </tr>
                <tr>
                  <td  :style="`border : 1px solid ${wsDARKLIGHT} ; border-top : none !important; background : ${wsLIGHTCARD}`">
                    <h5 :style="`color : ${wsACCENT}`">{{ $t('CertificateNumber') }}</h5>
                  </td>
                  <td :style="`border : 1px solid ${wsDARKLIGHT}; border-left : none !important; border-top : none !important;`">
                    <h5>{{ certificate.number}}</h5>
                  </td>
                </tr>
                <tr>
                  <td :style="`border : 1px solid ${wsDARKLIGHT} ; border-top : none !important; background : ${wsLIGHTCARD}`">
                    <h5 :style="`color : ${wsACCENT}`" >{{ $t('EducationStart') }}</h5>
                  </td>
                  <td  :style="`border : 1px solid ${wsDARKLIGHT}; border-left : none !important; border-top : none !important;`">
                    <h5>{{ certificate.date_registered ? PARSE_DATE(certificate.date_registered , false , true ) : null }}</h5>
                  </td>
                </tr>
                <tr>
                  <td :style="`border : 1px solid ${wsDARKLIGHT} ; border-top : none !important; background : ${wsLIGHTCARD}`">
                    <h5 :style="`color : ${wsACCENT}`" >{{ $t('CertificateIssuedAt') }}</h5>
                  </td>
                  <td  :style="`border : 1px solid ${wsDARKLIGHT}; border-left : none !important; border-top : none !important;`">
                    <h5>{{ certificate.date ?  PARSE_DATE(certificate.date, false , true)   : null}}</h5>
                  </td>
                </tr>
                <tr>
                  <td :style="`border : 1px solid ${wsDARKLIGHT} ; border-top : none !important; background : ${wsLIGHTCARD}`">
                    <h5 :style="`color : ${wsACCENT}`">{{ $t('CourseName') }}</h5>
                  </td>
                  <td :style="`border : 1px solid ${wsDARKLIGHT}; border-left : none !important; border-top : none !important;`">
                    <h5>{{ certificate.course_name }}</h5>
                  </td>
                </tr>
                <tr>
                  <td :style="`border : 1px solid ${wsDARKLIGHT} ; border-top : none !important; background : ${wsLIGHTCARD}`"
                      style="border-bottom-left-radius: 8px"
                  >
                    <h5 :style="`color : ${wsACCENT}`">{{ $t('SchoolName') }}</h5>
                  </td>
                  <td :style="`border : 1px solid ${wsDARKLIGHT}; border-left : none !important; border-top : none !important;`"
                      style="border-bottom-right-radius: 8px"
                  >
                    <h5 class="d-flex align-center">
                      {{ certificate.business_name }}
                      <ws-tooltip class="ml-2" :text="$t('BusinessIdentityOfficialAproved')">
                        <v-icon :color="wsACCENT">mdi-check-decagram</v-icon>
                      </ws-tooltip>

                    </h5>
                  </td>
                </tr>
                </tbody>
              </v-simple-table>
              <div
                  key="noData"
                  class="mt-16" v-else-if="searchFailed">
                <div class="d-flex justify-center">
                  <v-icon x-large class="mx-auto text-center" :color="wsACCENT">mdi-magnify</v-icon>
                </div>

                <h4 class="text-center font-weight-regular" :style="`color : ${wsACCENT}`">{{ $t('certificates.not_found') }}</h4>
              </div>

          </div>





        </v-sheet>
      </v-col>
    </v-row>



  </v-sheet>



</template>

<script>
import {mapActions} from "vuex";

export default {
  name: "checkCertificate",
  components: {
  },

  data() {
    return {
      certificateNumber : null,
      certificate : {},
      searchFailed : false,
      certificateError : null
    }
  },
  methods : {
    ...mapActions('certificates' , ['GET_COURSE_CERTIFICATE_PUBLIC']),

    async findCertificate() {
      if ( !this.certificateNumber ) {
        this.certificateError = this.$t('EnterCertificateNumber')
        return
      }

      let result = await this.GET_COURSE_CERTIFICATE_PUBLIC(this.certificateNumber)
      if ( !result || result === true ) {
        this.searchFailed = true
        this.certificate = {}
        return
      }
      this.searchFailed = false
      this.certificate = result

    },
    async initPage() {
      if ( !this.$store.state.QUERY.id  ) {
        return
      }
      this.certificateNumber = this.$store.state.QUERY.id
      if ( this.certificateNumber ) {
       this.findCertificate()
      }

    },
  },
  mounted() {
    this.initPage()
  }
}
</script>

<style scoped>

</style>